import { shuffleArray, WEB_URL } from '@centered/helpers'
import { Group } from '@centered/types'
import { useMemo } from 'react'
import SEOMetaTags from '../seo/SEOMetaTags'
import BasedOnScience from './BasedOnScience'
import GreatMusic from './GreatMusic'
import Header from '../shared/Header'
import HealthyBreaks from './HealthyBreaks'
import HeroSection from './HeroSection'
import SukhaCommunity from './SukhaCommunity'
import HowItWorks from './HowItWorks'
import LaserFocus from './LaserFocus'
import NewFooter from '../shared/NewFooter'
import OrgUsers from './OrgUsers'
import PhoneLocking from './PhoneLocking'
import Pricing from './Pricing'
import QuoteModule from './QuoteModule'
import { quotes } from './quotes'
import TheSolutionToYourProblem from './TheSolutionToYourProblem'
import Timeboxing from './Timeboxing'
import VideoWalkthrough from './VideoWalkthrough'
import Wrapper from '../shared/Wrapper'


interface LandingPageProps {
  featuredGroups: Group[]
}

export default function LandingPage({
  featuredGroups,
}: LandingPageProps): JSX.Element {
  const shuffledGroups = useMemo(() => {
    return shuffleArray(featuredGroups)
  }, [featuredGroups])

  const script = `
    var _avp = _avp || [];
    (function() {
      var s = document.createElement('script');
      s.type = 'text/javascript'; s.async = true; s.src = 'https://portal.smartrecognition.com/js/libcode3.js';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    })();
  `;

  const divScript = `
    var _avp = _avp || [];
    if (!document.cookie || document.cookie.indexOf('AVPDCAP=') == -1) { 
      _avp.push({ tagid: '9wlUguO64fMMDvO4wiiU', alias: '/', type: 'dynamic', zid: 7723, pid: 5234, secure: true });
    }
  `;
  

  return (
    <main className="overflow-x-hidden">
      <style jsx global>{`
        body {
          background-color: #000000;
          color: white;
        }
      `}</style>

      <SEOMetaTags
        title="Sukha-Focus for Remote Work"
        imageUrl={`${WEB_URL}/og-main-v3.png`}
      />

      <Header alwaysShowSignupButton={false} v7 />

      <HeroSection />

      <TheSolutionToYourProblem /> 

      <QuoteModule quote={quotes.beth} />
      
      <HowItWorks /> 

      <BasedOnScience />

      <GreatMusic />

      <Timeboxing />

      <HealthyBreaks />

      <PhoneLocking />

      <SukhaCommunity />

      <QuoteModule quote={quotes.marshall} reverse />

      <div className="w-full space-y-40 py-28">
        <VideoWalkthrough />
      </div>      
      
      <QuoteModule quote={quotes.kent} /> 

      <OrgUsers />

      <QuoteModule quote={quotes.matt} reverse />
      
      <Pricing />

      <QuoteModule quote={quotes.wagner} />  

      <FAQ />

      <LaserFocus />

      <QuoteModule quote={quotes.martin} reverse />

      <NewFooter />
      <script dangerouslySetInnerHTML={{ __html: script }} />
      <div data-tagid="9wlUguO64fMMDvO4wiiU">
        <script dangerouslySetInnerHTML={{ __html: divScript }} />
      </div>
    </main>
  )
}

const qa = [
  {
    question: 'Can’t I just do this without an app?',
    answer:
      'Yes, you totally can. Sukha just makes it easier.',
  },
  {
    question: 'Will using Sukha just distract me even more?',
    answer:
      'We designed Sukha to run in the background so you can focus on work.  There’s even a mini-player if you prefer that.',
  },
  {
    question: 'I already have too many tools.',
    answer:
      'Sukha is an all-in-one tool, so you can cancel your Pomodoro timer, music app, website/phone blocker, task list, etc. and save money.',
  },
  {
    question: 'Will Sukha run on my computer?',
    answer:
      'Sukha runs in any Chromium browser like Chrome, Edge, Brave, Arc, etc.',
  },
  {
    question: 'Is Sukha a surveillance tool?',
    answer: 'No. Member data is private to the individual member. We hate Facebook and all the companies that collect & sell your data; we don’t allow any of that garbage.',
  },
  {
    question: 'Why is Sukha any better than the 4,000 focus apps out there?',
    answer: (
      <>
        <u>Don't believe us</u>: You get a 14-day free trial, with no "input your credit card and we hope you forget to cancel" bs. You can start using Sukha in 30 seconds & find out for yourself. <br /> <u>Ask another user</u>: 94% of our members renew month-over-month. There is a reason for that.
      </>
    ),
  },
  {
    question: 'Is it easy to get started?',
    answer: 'Yes. Choose a task that will take 30 minutes or more. When you finish, take note of how you feel.',
  },
]

function FAQ(): JSX.Element {
  return (
    <Wrapper className="py-28">
      <h1 className="font-bold text-center text-2xl md:text-4xl mb-10">
        FAQ's
      </h1>

      <div className="space-y-5 text-lg">
        {qa.map((q, i) => (
          <div key={i}>
            <div className="text-red-600 font-bold">{q.question}</div>
            <div className="pl-12">{q.answer}</div>
          </div>
        ))}
      </div>
    </Wrapper>
  )
}
