import { trackEvent } from '@centered/analytics'
import Image from 'next/image'
import { useRef } from 'react'
import GoogleAuthCta from '../shared/GoogleAuthCta'
import Wrapper from '../shared/Wrapper'
import LockedPhoneImage from './assets/Locked-phone.jpg'
import AuthPageImage from './assets/auth_page.png'
import { useIsMobile } from '@centered/components/dist/hooks/useIsMobile'

export default function HeroSection(): JSX.Element {
  return (
    <Wrapper
      style={{
        minHeight: '15vh',
      }}
      className="mt-20"
    >
      <div className="hero flex flex-col mb-6 md:flex-row md:items-center space-x-6 md:space-x-12 items-center">
        <PhoneLockPreview />

        <div className="md:w-1/2 flex-shrink-0">
          <h1>
             <div className="text-3xl font-bold mb-8 md:mr-8 leading-snug">
              <br/>
              4. Phone Locking...
            </div>
          </h1>

          <p className="text-2xl md:mr-8 leading-normal mb-6">
            Silence the distractions with 1 click.
            <br />
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

export function PhoneLockPreview(): JSX.Element {
  return (
    <>
      <style jsx>{`
        .preview-image {
          width: 550px !important;
          aspect-ratio: 1.586;

        @media (min-width: 768px) {
          .preview-image {
            width: 800px !important;
          }
        }
      `}</style>

      <div className="w-1/2 overflow-hidden flex-shrink-0 preview-image relative transform -translate-x-10 md:translate-x-0">
        <Image
          src={LockedPhoneImage}
          alt="Cellphone locking feature"
          width={839}
          height={529}
          priority
          className="object-contain object-center"
        />
      </div>
    </>
  )
}

export function SigninHero(): JSX.Element {
  const isMobile = useIsMobile()

  return (
    <>
      <style jsx>{`
        .preview-image {
          width: 550px !important;
          aspect-ratio: 1;
        }

        @media (min-width: 768px) {
          .preview-image {
            width: 800px !important;
          }
        }
      `}</style>

      <div
        className={`'w-1/2 overflow-hidden flex-shrink-0 preview-image relative transform -translate-x-10 md:translate-x-0 cursor-pointer'${
          isMobile ? ' pt-4 px-6' : ''
        }`}
      >
        <Image
          src={AuthPageImage}
          alt="Sukha"
          width={isMobile ? 350 : 800}
          height={isMobile ? 350 : 800}
          priority
          className="object-contain object-center"
        />
      </div>
    </>
  )
}
