import Image from 'next/image'
import TryCenteredButton from './TryCenteredButton'
import Background from './assets/lightspeed1.jpeg'

export default function TheSolutionToYourProblem(): JSX.Element {
  return (
    <div className="relative w-full py-36 bg-black">
      <Image
        src={Background}
        fill
        role="presentation"
        alt=""
        className="object-cover object-center"
      />

      <div className="text-center relative z-20 px-4 text-white">
        <h2 className="font-bold text-4xl mb-4">
          Sick of that 6pm <br/>"Where did the day go?!"<br/>
          <br/>
          Try a 3pm <br/>"Wow, I got everything done..."
        </h2>
      </div>
    </div>
  )
}
